// APIアクセス用のヘルパ

import pickBy from 'lodash.pickby'
import isNil from 'lodash.isnil'

import store from '../store'

export default async ({
  method = 'get',
  url,
  params,
  // 認証が必要か
  auth = false,
  // トークンのオーバーライド
  token = null,
  // ローディング中にしないか
  silent = false,
}) => {
  if (!silent) store.dispatch('app/incrementLoadings')

  const _url = /^https?:\/\//.test(url) ? url : (process.env.VUE_APP_API_BASE_URL || '') + url

  // paramsのうち、nullやundefinedは除去
  const _params = params ? pickBy(params, (item) => !isNil(item)) : null
  const headers = {}
  if (_params) headers['Content-Type'] = 'application/json'
  if (auth) {
    const _token = token || store.getters['app/token']
    if (_token) headers['Authorization'] = _token
  }

  try {
    const res = await fetch(_url, {
      method,
      credentials: 'same-origin',
      body: _params ? JSON.stringify(_params) : null,
      headers,
    })
    const payload = await res.json()
    return {
      ok: res.ok,
      status: res.status,
      payload,
    }
  } catch (e) {
    return {
      ok: false,
    }
  } finally {
    if (!silent) store.dispatch('app/decrementLoadings')
  }
}
