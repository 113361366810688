// お知らせの一覧

<template lang="pug">
.information-list
  p.note(v-if='!informations.length')
    | お知らせはありません。
  ul(v-else)
    li(v-for='item in informations')
      a(@click='click(item.id)')
        span {{ item.date | jpDate }}
        span {{ item.title }}

  information-detail-dialog(
    :visible.sync='dialogVisible',
    :id='informationId',
    :is-admin='isAdmin'
  )
</template>

<script>
import getInformationListApi from '@/api/get-information-list'
import getInformationListAdminApi from '@/api/admin/get-informations'
import InformationDetailDialog from '@/dialogs/information-detail-dialog.vue'

export default {
  name: 'InformationList',

  components: { InformationDetailDialog },

  props: {
    // 表示件数
    displayNum: {
      type: Number,
    },
    // 自分が管理者かどうか
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      informations: [],
      dialogVisible: false,
      informationId: 0,
    }
  },

  async created() {
    let response
    if (this.isAdmin) {
      response = await getInformationListAdminApi()
    } else {
      response = await getInformationListApi()
    }
    if (!response.ok) return
    if (this.displayNum) {
      this.informations = response.payload.items.slice(0, this.displayNum)
    } else {
      this.informations = response.payload.items
    }
  },

  methods: {
    click(id) {
      this.informationId = id
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="sass" scoped>
.information-list
  border: 1px solid $color-1
  border-radius: 5px
  p.note
    padding: 1rem 0.5rem

  ul
    list-style-type: none
    padding: 0.5rem
    li
      border-bottom: 1px solid $color-1
      &:last-child
        border-bottom: none
      a
        display: block
        padding: 0.5rem 0.5rem
        text-decoration: none
        span:first-child
          color: darken($color-1, 20%)
          font-weight: bold
        span:nth-child(2)
          color: $grey-dark
          margin-left: 1rem
        cursor: pointer
        &:hover
          background: lighten($color-1, 40%)
</style>
