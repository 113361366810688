// お知らせの詳細の取得

import api from './api'

const path = '/informations/{id}'

export default (informationId) => {
  const url = path.replace('{id}', informationId)
  return api({
    url,
    auth: true,
  })
}
