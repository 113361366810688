// 管理者向けのすべてのお知らせの取得

import api from '../api'

const path = '/admin/informations'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
