// お知らせの一覧の取得

import api from './api'

const path = '/informations'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
