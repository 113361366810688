// お知らせの詳細表示ダイアログ

<template lang="pug">
el-dialog.information-detail-dialog(
  title='お知らせの詳細',
  width='60%',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  article(v-if='detail')
    h1 {{ detail.title }}
    p {{ detail.date | jpDate }}
    pre {{ detail.body }}
    .attachments(v-if='detail.attachments')
      h3 添付ファイル
      ul
        li(v-for='item in detail.attachments')
          a(:href='item.url', target='_blank', rel='noopener')
            i.el-icon-document
            span {{ item.name }}
</template>

<script>
import getInformationApi from '@/api/get-information'
import getInformationAdminApi from '@/api/admin/get-information'

export default {
  data() {
    return {
      detail: null,
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // お知らせの記事のID
    id: {
      type: Number,
    },
    // 自分が管理者かどうか
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async open() {
      if (!this.id) return
      let response
      if (this.isAdmin) {
        response = await getInformationAdminApi(this.id)
      } else {
        response = await getInformationApi(this.id)
      }
      if (!response.ok) return
      this.detail = response.payload
    },
  },
}
</script>

<style lang="sass">
.information-detail-dialog
  .el-dialog__body
    padding-top: 0
</style>

<style lang="sass" scoped>
article
  h1
    font-size: 1.2rem
    border-bottom: 1px solid $color-1
  p
    font-size: 0.9rem
    font-weight: bold
    color: darken($color-1, 20%)
    margin-top: 1rem
  pre
    margin-top: 1rem
    white-space: pre-wrap
    font-size: 1rem
  .attachments
    margin-top: 2rem
    border: 1px solid $grey-lighter
    background: $white-ter
    border-radius: 5px
    padding: 1rem
    h3
      font-size: 0.9rem
      color: $grey
    ul
      list-style-type: none
      margin-top: 0.5rem
      li
        display: inline-block
        margin-right: 1.5rem
</style>
