// ホーム画面用のメニュー // 分岐が美しくないので用調整

<template lang="pug">
ul.home-menu(:class='{ shrink: shrink }')
  li(v-for='item in items', :class='item.className')
    router-link(v-if='!item.external', :to='{ name: item.to }')
      el-row(type='flex', align='middle', :gutter='20')
        el-col(style='flex: 0;')
          .text-2xl.text-white
            fa-icon(:icon='item.icon')
        el-col(style='flex: 1; z-index: 1')
          div
            h3.font-bold {{ item.label }}
            p(v-if='item.description') {{ item.description }}
        el-col(style='flex: 0;')
          i.el-icon-arrow-right
    a(
      v-else,
      :href='item.to',
      target='_blank',
      rel='noopener'
    )
      el-row(type='flex', align='middle', :gutter='20')
        el-col(style='flex: 0;')
          .text-2xl.text-white
            fa-icon(:icon='item.icon')
        el-col(style='flex: 1; z-index: 1')
          div
            h3.font-bold {{ item.label }}
            p(v-if='item.description') {{ item.description }}
        el-col(style='flex: 0;')
          i.el-icon-arrow-right
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.home-menu
  list-style-type: none
  overflow: hidden
  margin: 0 -10px
  li
    float: left
    width: 50%
    padding: 10px
    +mobile
      width: 100%
    a
      display: block
      background: $grey-lighter
      border-radius: 3px
      padding: 1rem
      color: $grey-dark
      text-decoration: none
      > div
        min-height: 3rem
      img
        display: block
        max-width: 100px
        min-width: 50px
        width: 100%
        margin: 0 auto
        opacity: 0.7
      +mobile
        padding: 1rem
        > div
          min-height: 0
        img
          display: none
        h3
          font-size: 1rem
        p
          font-size: 0.85rem
    &.messages a
      background: linear-gradient(90deg, #59D7DF 0%, #98E8ED 30.06%)
    &.document a
      background: linear-gradient(90deg, #59DF66 0%, #A9ED98 30.06%)
    &.seminar a
      background: linear-gradient(90deg, #FFC268 0%, #FDD89F 30.06%)
    &.survey a
      background: linear-gradient(90deg, #FFA183 0%, #FFCABE 30.06%)
  &.shrink
    li
      a
        img
          max-width: 60px
</style>
