// メンバー向けのトップページ

<template lang="pug">
el-card.member-home
  home-menu(:items='homeMenuItems')

  .information(v-if='role === "org" || role === "staff"')
    el-row(type='flex', align='middle')
      el-col(:span='18')
        h2 最近のお知らせ
      el-col(:span='6')
        p
          router-link(:to='{ name: "MemberInformations" }')
            | お知らせ一覧
            i.el-icon-caret-right
    information-list.list(:displayNum='5')
</template>

<script>
import { mapGetters } from 'vuex'

import HomeMenu from '@/components/home-menu'
import InformationList from '@/components/information-list'

import getHomeMenuItems from '@/helpers/get-home-menu-items'

export default {
  components: {
    HomeMenu,
    InformationList,
  },

  computed: {
    ...mapGetters({
      role: 'app/role',
    }),
    homeMenuItems() {
      return getHomeMenuItems(this.role)
    },
  },
}
</script>

<style lang="sass" scoped>
.member-home
  padding-bottom: 3rem

.information
  margin-top: 2rem
  h2
    font-weight: normal
  p
    text-align: right
  .list
    margin-top: 0.5rem
</style>
