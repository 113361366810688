// ホーム画面で表示するメニュー項目を取得

const menuItemsMap = {
  org: [
    {
      label: 'メッセージ受信箱',
      description: '自分宛てのメッセージを確認することができます。',
      icon: 'envelope',
      to: 'MemberMessages',
      className: 'messages',
    },
    {
      label: '書類のダウンロード',
      description: '各種様式・書類をダウンロードすることができます。',
      icon: 'file-alt',
      to: 'MemberDocuments',
      className: 'document',
    },
    {
      label: '研修等の申し込み',
      description: '各種研修等の閲覧や参加申し込みをすることができます。',
      icon: 'graduation-cap',
      to: 'https://member.nagoyashiyo.or.jp/seminar/',
      className: 'seminar',
      external: true,
    },
    {
      label: 'アンケート',
      description: '自分宛てのアンケートの確認と回答をすることができます。',
      icon: 'clipboard',
      to: 'MemberSurveys',
      className: 'survey',
    },
  ],
  staff: [
    {
      label: '研修等の申し込み',
      description: '各種研修等の閲覧や参加申し込みをすることができます。',
      icon: 'graduation-cap',
      to: 'https://member.nagoyashiyo.or.jp/seminar/',
      className: 'seminar',
      external: true,
    },
  ],
  admin: [
    {
      label: 'メッセージの管理',
      icon: 'envelope',
      to: 'AdminMessages',
      className: 'messages',
    },
    {
      label: '各種様式の管理',
      icon: 'file-alt',
      to: 'AdminDocuments',
      className: 'document',
    },
    {
      label: '研修の管理',
      icon: 'graduation-cap',
      to: 'https://member.nagoyashiyo.or.jp/seminar/',
      className: 'seminar',
      external: true,
    },
    {
      label: 'アンケートの管理',
      icon: 'clipboard',
      to: 'AdminSurveys',
      className: 'survey',
    },
    {
      label: '会員園の管理',
      icon: 'cog',
      to: 'AdminOrganizations',
    },
    {
      label: '受講者グループの管理',
      icon: 'cog',
      to: 'AdminGroups',
    },
    {
      label: 'アプリユーザーの管理',
      icon: 'cog',
      to: 'AdminAppUsers',
    },
    {
      label: 'アプリ通知履歴',
      icon: 'cog',
      to: 'AdminAppNotifications',
    },
    {
      label: 'お知らせの管理',
      icon: 'cog',
      to: 'AdminInformations',
    },
    {
      label: '各種設定',
      icon: 'cog',
      to: 'dummy',
    },
  ],
}

export default (role) => menuItemsMap[role] || []
