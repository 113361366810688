// 管理者向けのお知らせの取得

import api from '../api'

const path = '/admin/informations/{id}'

export default (informationId) => {
  const url = path.replace('{id}', informationId)
  return api({
    url,
    auth: true,
  })
}
